import { includes, prop } from 'ramda'
import { entity, fetchResource } from './api'
import { FeatureFlags, UserType } from './types'
import { UserResponse } from './users'

export const isAdminUser = user => {
  return (
    user &&
    includes('admin', (user.target ? user.target.roles : user.roles) || [])
  )
}

export const isDeveloperUser = user => {
  return (
    user &&
    includes('developer', (user.target ? user.target.roles : user.roles) || [])
  )
}

export type AdminRole =
  | 'super'
  | 'cases'
  | 'groups'
  | 'institutions'
  | 'users'
  | 'learning'
  | 'subscriptions'
  | 'cms'
  | 'comms'

export const useUser = (): UserType =>
  entity(fetchResource('/api/profile/me', UserResponse))

export const getAdminRoleLabel = (role: AdminRole): string => {
  switch (role) {
    case 'cases':
      return 'Posts admin'
    case 'groups':
      return 'Groups admin'
    case 'institutions':
      return 'Institution admin'
    case 'users':
      return 'User admin'
    case 'learning':
      return 'Learning admin'
    case 'subscriptions':
      return 'Subscriptions'
    case 'cms':
      return 'Content management'
    case 'comms':
      return 'Communications management'
    default:
      throw new Error(`Unexpected role ${role}`)
  }
}

export const hasAdminRole = (user, role: AdminRole) =>
  user &&
  (includes('admin', (user.target ? user.target.roles : user.roles) || []) ||
    includes(
      `admin-${role}`,
      (user.target ? user.target.roles : user.roles) || []
    ))

export const useFeatureFlags = (): FeatureFlags => {
  return prop('feature_flags', fetchResource('/api/profile/me', UserResponse))
}
