import React, { useState, useEffect } from 'react'
import {
  Container,
  Dropdown,
  Image,
  Loader,
  Menu,
  Segment,
  Icon,
} from 'semantic-ui-react'

import cs from 'classnames'
import { useUser } from '../models/auth'
import { UserType } from '../models/types'
import { useSitemap } from '../models/url'
import { useWindowSize } from '../lib/use-window-size'
import Link from './Link'
import { Notifications } from './Notifications'
import { ErrorBoundary } from './ErrorBoundary'
import { MainNavigationMenu } from './navigation-menu'
import { mediaLessThan } from '../components/Responsive'

type LayoutType = {
  renderHeader?: Function
  renderMenu?: Function
  snug?: boolean
  loading?: boolean
  children: React.ReactNode
  withContainer?: boolean
}

const UserMenu = ({ compact = false }) => {
  const site = useSitemap()
  const user = useUser()
  if (!user) {
    return null
  }
  const profile = (
    <span>
      <Image
        src={user.avatar_url.replace('{image_size}', 'profile-thumb')}
        avatar
        spaced={!compact}
      />
      {compact ? null : <span>{user.name}</span>}
    </span>
  )
  return (
    <Link {...site.profileURL(user)}>
      <a>{profile}</a>
    </Link>
  )
  return (
    <Dropdown item simple trigger={profile} className="right">
      <Dropdown.Menu>
        <Dropdown.Item>List Item</Dropdown.Item>
        <Dropdown.Item>List Item</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Header Item</Dropdown.Header>
        <Dropdown.Item>
          <i className="dropdown icon" />
          <span className="text">Submenu</span>
          <Dropdown.Menu>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown.Item>
        <Dropdown.Item>List Item</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const StandardLayout = ({
  renderHeader,
  snug,
  children,
}: LayoutType) => {
  const site = useSitemap()
  const user = useUser()

  return (
    <div className={snug ? 'layout snug' : 'layout'}>
      <header className="header">
        <Menu fixed="top" className="header__menu" inverted>
          <Container>
            <Menu.Item fitted as="a" href={site.homePage().href} header>
              <Image
                alt="MedShr"
                src="https://static.medshr.net/logo/logo-white-605x534.png"
                width="40"
              />
            </Menu.Item>
            <Menu.Item position="right">
              <UserMenu />
            </Menu.Item>
          </Container>
        </Menu>
        {renderHeader && renderHeader()}
      </header>
      <GhostBanner currentUser={user} />
      <Container style={{ marginTop: renderHeader ? '2em' : '5em' }}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Container>
      <Notifications />
      <Segment as="footer" inverted vertical className="footer">
        <Container textAlign="right" className="copyright">
          © 2015 - {new Date().getFullYear()} MedShr. All rights reserved.
        </Container>
      </Segment>
    </div>
  )
}

export const MaxLayout = ({
  renderHeader,
  renderMenu,
  snug,
  children,
  withContainer = true,
  loading,
}: LayoutType) => {
  const site = useSitemap()
  const [wantNav, setWantNav] = useState(false)
  const size = useWindowSize()
  const user = useUser()

  const canToggleNav = size.width > 0 && mediaLessThan(size.width, 'computer')
  const visibleNav = wantNav || (size.width > 0 && !canToggleNav)

  useEffect(() => {
    if (visibleNav && canToggleNav) {
      document.getElementById('__next').style.overflow = 'hidden'
    } else {
      document.getElementById('__next').style.overflow = 'visible'
    }
  }, [visibleNav, canToggleNav])

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  let body

  if (loading) {
    body = <Loader active />
  } else if (withContainer) {
    body = (
      <Container
        fluid
        style={{ padding: snug ? '0' : '1em 2em' }}
        className="main-content__container"
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </Container>
    )
  } else {
    body = <ErrorBoundary>{children}</ErrorBoundary>
  }

  return (
    <div className={snug ? 'layout snug' : 'layout'}>
      <header className="header">
        <Menu fixed="top" className="header__menu" inverted>
          <Container fluid>
            {canToggleNav ? (
              <Menu.Item>
                <div
                  className={cs('main-menu-toggler', {
                    'main-menu-toggler--collapsed': !visibleNav,
                  })}
                  onClick={() => setWantNav(!visibleNav)}
                >
                  <Icon name="sidebar" size="large" />
                </div>
              </Menu.Item>
            ) : null}
            <Menu.Item
              as="a"
              href={site.homePage().href}
              header
              style={{
                flexGrow: canToggleNav ? 2 : 0,
                justifyContent: 'center',
              }}
            >
              <Image
                alt="MedShr"
                src="https://static.medshr.net/logo/logo-white-605x534.png"
                width="40"
              />
            </Menu.Item>
            <Menu.Item position="right">
              <UserMenu compact={canToggleNav} />
            </Menu.Item>
          </Container>
        </Menu>
        {renderHeader && renderHeader()}
      </header>
      <GhostBanner currentUser={user} />

      <div className={cs('main-menu', { 'main-menu--visible': visibleNav })}>
        {renderMenu ? renderMenu() : <MainNavigationMenu />}
      </div>

      <div
        className={cs('main-content', {
          'main-content--no-margin': !visibleNav,
        })}
      >
        {body}
      </div>

      <Notifications />

      <Segment as="footer" inverted vertical className="footer">
        <Container
          fluid
          textAlign="right"
          style={{ padding: '0 1em' }}
          className="copyright"
        >
          © 2015 - {new Date().getFullYear()} MedShr. All rights reserved.
        </Container>
      </Segment>
    </div>
  )
}

const GhostBanner = ({ currentUser }: { currentUser: UserType }) => {
  if (!currentUser?.is_ghost) return null

  return (
    <div className="ghost-banner">
      <div className="page__container ghost-banner__inner">
        <p>
          <span role="img" aria-label="waving hand">
            👋
          </span>{' '}
          You are currently impersonating <strong>{currentUser?.name}</strong>.
        </p>
      </div>
    </div>
  )
}
