import React from 'react'
import { map } from 'ramda'
import { Message, Transition } from 'semantic-ui-react'
import { useNotifications } from '../models/notifications'

export const Notifications = () => {
  const notifications = useNotifications()

  return notifications.length ? (
    <div className="notifications-area">
      {map(
        message => (
          <Transition
            key={message.id}
            visible={message.visible}
            duration={300}
            animation="scale"
          >
            <div style={{ marginBottom: 10 }}>
              <Message
                header={message.title}
                content={message.text}
                icon={message.icon}
                color={message.color}
              />
            </div>
          </Transition>
        ),
        notifications
      )}
    </div>
  ) : null
}
