export const objectID = x =>
  x && typeof x === 'object'
    ? typeof x.target === 'object'
      ? x.target.id
      : x.id
    : x

export const objectType = x =>
  x && typeof x === 'object'
    ? typeof x.target === 'object'
      ? x.target.type
      : x.type
    : x
