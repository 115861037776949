import { denormalize, normalize, schema } from 'normalizr'
import { path } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSWR, { cache, mutate } from 'swr'
import {
  DocsearchSearchesResponse,
  DocsearchSearchResponse,
} from '../components/RichEditor/Plugins/SavedSearch/types'

import CategoryRenderer from './CategoryRenderer'
import {
  fetchApi,
  useAuthenticatedDeleteRequest,
  useAuthenticatedPostRequest,
} from './fetchApi'
import { displayNotification } from './notifications'
import { Categories, DedupedAPIResponse } from './types'

const CategoriesSchema = new schema.Entity(
  'categories',
  {},
  { idAttribute: () => 'list' }
)

const CategoriesResponse = new schema.Entity(
  'categories_response',
  {
    response: CategoriesSchema,
  },
  { idAttribute: '__url__' }
)

export const selectResource = (
  apiPath: string,
  schema: schema.Entity,
  id?: string
) =>
  useSelector((state: { entities: any }) =>
    path(['entities', schema.key, id || apiPath], state)
      ? denormalize(id || apiPath, schema, state.entities || {})
      : undefined
  )

export const forgetResource = (dispatch, apiPath, schema, id = undefined) => {
  const statusPath = ['api', schema.key, apiPath]
  const resourcePath = ['entities', schema.key, id || apiPath]
  dispatch({ type: 'DELETE', path: statusPath })
  dispatch({ type: 'DELETE', path: resourcePath })
}

export const updateIsActive = (apiPath, schema) => {
  const statusPath = ['api', schema.key, apiPath, 'active']
  return !!useSelector(path(statusPath))
}

export const useResourceUpdater = () => {
  const dispatch = useDispatch()
  const post = useAuthenticatedPostRequest()
  return async (
    apiPath,
    schema,
    body,
    files = undefined,
    storeKey = undefined
  ) => {
    const statusPath = ['api', schema.key, apiPath]
    dispatch({
      type: 'UPDATE',
      path: statusPath,
      value: {
        active: true,
        complete: false,
        started_at: new Date().getTime(),
      },
    })
    const res = await post(apiPath, body, files)
    const normalized = normalize(
      { ...res.body, __url__: storeKey || apiPath },
      schema
    )
    dispatch({
      type: 'MERGE_DEEP',
      path: ['entities'],
      value: normalized.entities,
    })
    dispatch({
      type: 'UPDATE',
      path: statusPath,
      value: {
        active: false,
        complete: true,
        completed_at: new Date().getTime(),
      },
    })
    return res.body
  }
}

export const useResourceDeleter = () => {
  const dispatch = useDispatch()
  const remove = useAuthenticatedDeleteRequest()
  return async (apiPath, schema, id) => {
    const statusPath = ['api', schema.key, apiPath]
    dispatch({
      type: 'UPDATE',
      path: statusPath,
      value: {
        active: true,
        complete: false,
        started_at: new Date().getTime(),
      },
    })
    await remove(apiPath)
    dispatch({
      type: 'DELETE',
      path: ['entities', schema.key, id || apiPath],
    })
    dispatch({
      type: 'UPDATE',
      path: statusPath,
      value: {
        active: false,
        complete: true,
        completed_at: new Date().getTime(),
      },
    })
    return true
  }
}

export const useResourceReloader = () => {
  const dispatch = useDispatch()
  return async (apiPath, schema, id = undefined) => {
    const { get } = fetchApi
    const res = await get(apiPath)
    const normalized = normalize({ ...res, __url__: id || apiPath }, schema)
    dispatch({
      type: 'MERGE_DEEP',
      path: ['entities'],
      value: normalized.entities,
    })
    return res
  }
}

export const fetchResource = (
  apiPath: string,
  schema: schema.Entity,
  callback?: () => unknown
) => {
  const { get } = fetchApi
  return fetchResourceCallback(apiPath, schema, () => {
    const value = callback ? callback() : null
    return value ? Promise.resolve({ body: value }) : get(apiPath)
  })
}

type APIStatus =
  | {
      active: true
      complete: false
      started_at: number
    }
  | {
      active: false
      complete: true
      completed_at: number
    }
  | {
      active: false
      complete: false
      error: true
      exception: Error
      error_at: number
    }

const fetchResourceCallback = (apiPath, schema, callback) => {
  const statusPath = ['api', schema.key, apiPath]
  const dispatch = useDispatch()
  const value = selectResource(apiPath, schema)
  const status: APIStatus | undefined = useSelector(
    state => path(statusPath, state) || undefined
  )
  useEffect(() => {
    try {
      if (
        status &&
        'error' in status &&
        status.error &&
        'error_at' in status &&
        status.error_at > new Date().getTime() - 30000 &&
        'exception' in status
      ) {
        const e = status.exception
        throw e
      }
      if (typeof value === 'undefined' && (!status || !status.active)) {
        const fetchResource = async () => {
          dispatch({
            type: 'UPDATE',
            path: ['api', schema.key, apiPath],
            value: {
              active: true,
              complete: false,
              started_at: new Date().getTime(),
            },
          })
          try {
            const res = await callback()
            const normalized = normalize({ ...res, __url__: apiPath }, schema)
            dispatch({
              type: 'MERGE_DEEP',
              path: ['entities'],
              value: normalized.entities,
            })
            dispatch({
              type: 'UPDATE',
              path: statusPath,
              value: {
                active: false,
                complete: true,
                completed_at: new Date().getTime(),
              },
            })
          } catch (e) {
            // window.responseError = e;
            e.retry = { type: 'DELETE', path: statusPath }
            dispatch({
              type: 'UPDATE',
              path: statusPath,
              value: {
                active: false,
                complete: false,
                error: true,
                exception: e,
                error_at: new Date().getTime(),
              },
            })
          }
        }
        void fetchResource()
      }
    } catch (err) {
      console.log(err)
    }
  })
  return value
}

export const useDedupedAPI = function<T>(
  apiPath: string,
  options = {}
): DedupedAPIResponse<T> {
  if (!options.hasOwnProperty('dedupingInterval')) {
    options['dedupingInterval'] = 300000
  }
  const { get } = fetchApi
  const { data, error } = useSWR(apiPath, get, options)
  return {
    data: data ? data : data,
    error,
    loading: !data && !error,
    refresh: (path = undefined) => refreshPrefix(path ?? apiPath),
  }
}

export const useDebouncedAPI = <T extends unknown>(
  id: string,
  schema: schema.Entity,
  apiPath: string,
  empty = false,
  duration = 800
): [T, boolean] => {
  const dispatch = useDispatch()
  const reloadResource = useResourceReloader()
  const [timer, setTimer] = useState(undefined)
  const value = selectResource(apiPath, schema, id)

  useEffect(() => {
    clearTimeout(timer)
    if (empty) {
      if (value) {
        forgetResource(dispatch, apiPath, schema, id)
      }
      setTimer(null)
    } else {
      setTimer(
        setTimeout(async () => {
          await reloadResource(apiPath, schema, id)
          setTimer(null)
        }, duration)
      )
    }
    return () => clearTimeout(timer)
  }, [apiPath])
  return [value, !!timer]
}

/*
type mutateMany = (
  select: (key: string) => boolean, // select which keys to update
  update: any | (data: any) => any, // update the dat
  // define if it should revalidate that key
  shouldRevalidate: boolean | (key: string) => boolean
) => void;
*/

type StringFilterFunction = (key: string) => boolean

export const mutateMany = (
  select: StringFilterFunction,
  update: (data: any) => any,
  shouldRevalidate: boolean | StringFilterFunction = true
) =>
  cache
    .keys()
    .filter(select)
    .forEach(key => {
      const newData = update(cache.get(key))
      mutate(
        key,
        newData,
        typeof shouldRevalidate === 'function'
          ? shouldRevalidate(key)
          : shouldRevalidate
      )
    })

export const mutatePrefix = (prefix, update, shouldRevalidate = true) =>
  mutateMany(key => key.startsWith(prefix), update, shouldRevalidate)

export const refreshPrefix = (prefix, shouldRevalidate = true) =>
  mutatePrefix(prefix, x => undefined, shouldRevalidate)

export const purgePrefix = (prefix, shouldRevalidate = true) =>
  mutatePrefix(prefix, x => null, shouldRevalidate)

export const getCache = key => cache.get(key)

type MenuGroup = {
  id: string
  title: string
}

type MenuLink = {
  url: string
  title: string
}

type MenuItem = (MenuGroup | MenuLink) & {
  menu?: MenuItem[]
}

export const useMenu = () =>
  useDedupedAPI<{ menu: MenuItem[] }>('/api/dashboard/menu')

export const entity = res => (res ? res.response : undefined)

export const list = res => res || undefined

export const useCategories = () =>
  entity(fetchResource('/api/categories', CategoriesResponse))

export const selectCategories = (): Categories | undefined =>
  useSelector(state => path(['entities', 'categories', 'list'], state))

export const useCategoryRenderer = () => {
  const categories = selectCategories()
  return useMemo(
    () => (categories ? CategoryRenderer(categories) : undefined),
    [!!categories]
  )
}

export const useSavedSearches = (title?: string) =>
  useDedupedAPI<DocsearchSearchesResponse>(
    '/admin/api/docsearch_searches' +
      (typeof title === 'string' && title.length
        ? '?title=' + encodeURIComponent(title)
        : '')
  )

export const useSavedSearch = (id: string) =>
  useDedupedAPI<DocsearchSearchResponse>(`/admin/api/docsearch_searches/${id}`)

export const useResponseNotifier = () => {
  const dispatch = useDispatch()
  return (response, defaultNotification = undefined) => {
    if (
      response instanceof Error &&
      typeof response['response'] !== 'undefined'
    ) {
      dispatchResponseNotification(
        dispatch,
        response['response'],
        defaultNotification
      )
    } else {
      dispatchResponseNotification(dispatch, response, defaultNotification)
      return response
    }
  }
}

export const dispatchResponseNotification = (
  dispatch,
  response,
  defaultNotification = undefined
) => {
  if (response && response.body) {
    if (response.body.message) {
      dispatch(
        displayNotification({ text: response.body.message, color: 'green' })
      )
      return true
    }
    if (response.body.errors) {
      response.body.errors.forEach(error =>
        dispatch(displayNotification({ text: error.title, color: 'red' }))
      )
      return true
    }
    if (response.body.error) {
      dispatch(displayNotification({ text: response.body.error, color: 'red' }))
      return true
    }
  }
  if (defaultNotification) {
    dispatch(displayNotification(defaultNotification))
    return true
  }
}
