import { useEffect, useState } from 'react'
import { ParsedUrlQuery } from 'querystring'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { concat, filter, map, omit, fromPairs, toPairs } from 'ramda'
import formatISO from 'date-fns/formatISO'
import { objectID } from './object'
import { isNull } from 'util'

export const toSlug = (title: string, trim = false) => {
  const t = title
    .toLowerCase()
    .replace(/&/g, 'and')
    .replace(/[^0-9a-z-]/g, '-')
    .replace(/-{2,}/g, '-')
  if (trim) {
    return t.replace(/^-*(.+?)-*$/, '$1')
  }
  return t
}

const parameters = xs => filter(([k, v]) => !!v, xs)

const urlencodeURIComponent = (
  x: string | Date | number | undefined
): string => {
  if (typeof x === 'undefined') {
    return ''
  }
  if (x instanceof Date) {
    return formatISO(x)
  }
  return encodeURIComponent(x.toString())
}

export const getQueryStringForObject = (params: {
  [k: string]: string | Date | number | undefined | string[]
}): string =>
  parameters(toPairs(params))
    .map(([k, v]) =>
      Array.isArray(v)
        ? v.map(v => `${k}[]=${urlencodeURIComponent(v)}`).join('&')
        : `${k}=${urlencodeURIComponent(v)}`
    )
    .join('&')

const url = (pageName, asPrefix, parameters) => {
  if (parameters.length) {
    const href = `/${pageName.replace(/^\/+/, '')}?${map(
      ([k, v]) => `${k}=${encodeURIComponent(v)}`,
      parameters
    ).join('&')}`
    const as = `/${asPrefix.replace(/^\/+/, '')}/${map(
      ([k, v]) => v,
      parameters
    ).join('/')}`
    return { href, as }
  }
  return {
    href: `/${pageName.replace(/^\/+/, '')}`,
    as: `/${asPrefix.replace(/^\/+/, '')}`,
  }
}

type QueryState = {
  [key: string]: any
}

export type SiteMap = {
  useQueryStates: <T extends QueryState>(
    defaultState: T
  ) => [T, (value: T) => void]

  useQueryState: (
    key: string,
    defaultValue?: string,
    onChange?: (key: string, value: string) => void
  ) => [string | undefined, (value: string) => void]

  useQueryNumber: (
    key: string,
    defaultValue?: number
  ) => [number | undefined, (value?: number) => void]

  queryParameter: (name: string, defaultValue?: string) => string | undefined

  queryParameterInteger: (
    name: string,
    defaultValue?: number,
    min?: number,
    max?: number
  ) => number

  query: ParsedUrlQuery

  navigate: (to: string | { href: string; as?: string }) => void
  up: () => void

  populatePath: (path: string, vars: { [k: string]: string }) => string

  getURLWithQuery: (query: { [k: string]: string }) => string

  here: () => string

  isCurrentPath: (path: string) => boolean
  isCurrentPathPrefix: (path: string, indexOnly?: boolean) => boolean
  isCurrentPathChild: (path: string) => boolean

  mainSiteURL: (path: string) => string
  apiURL: (path: string) => string
  dashboardURL: (path: string) => string

  homePage: () => { href: string }
  profileURL: (user) => { href: string }
  groupProfileURL: (group) => { href: string }
  groupEditURL: (group) => { href: string }
  caseURL: (entry) => { href: string }
  caseDashboardURL: () => { href: string }
  casePDFURL: (entry) => { href: string }
  commentsDashboardURL: () => { href: string }

  groupDashboardURL: (group, section?: string) => { href: string; as: string }
  groupImportJobURL: (group, job) => string
  groupExportURL: (group, exportName) => { href: string }

  sidebarResourceDashboardURL: () => { href: string }
  institutionProfileURL: (institution) => { href: string }
  institutionDashboardURL: () => { href: string }
  unverifiedInstitutionDashboardURL: () => { href: string }
  carouselDashboardURL: () => { href: string }

  nativeAdsDashboardURL: () => { href: string }
  promoLinksAdsDashboardURL: () => { href: string }
  appInviteScreensDashboardURL: () => { href: string }

  segmentDashboardURL: () => { href: string }
  newsletterEditorURL: (path: string) => string
}

export const useSitemap = (): SiteMap => {
  const siteConfig = getConfig().publicRuntimeConfig
  const router = useRouter()

  const mainSiteURL = path =>
    `${siteConfig.urlScheme}://${siteConfig.mainHostName}${path}`

  const apiURL = path =>
    `${siteConfig.urlScheme}://${siteConfig.backendHostName}${path}`

  const dashboardURL = path =>
    `${siteConfig.urlScheme}://${siteConfig.dashboardHostName}${path}`

  const query = router.query || {}

  const queryParameter = (
    name: string,
    defaultValue?: string
  ): string | undefined => {
    const val = router.query[name]
    if (typeof val === 'undefined') {
      return defaultValue
    }
    if (typeof val === 'string') {
      return val
    }
    if (Array.isArray(val)) {
      return val[0]
    }
    return defaultValue
  }

  const queryParameterArray = (
    name: string,
    defaultValue?: string[]
  ): string[] | undefined => {
    const val = router.query[`${name}[]`]
    if (typeof val === 'undefined') {
      return defaultValue
    }
    if (typeof val === 'string') {
      return [val]
    }
    if (Array.isArray(val)) {
      return val
    }
    return defaultValue
  }

  const queryParameterInteger = (
    name: string,
    defaultValue = 0,
    min = 0,
    max: number = Number.MAX_SAFE_INTEGER
  ): number =>
    Math.min(
      max,
      Math.max(min, parseInt(queryParameter(name, '0'), 10) || defaultValue)
    )

  const routePlaceholders = (path: string) =>
    (path.match(/\[([^\]]+)\]/g) || []).map(s => s.substring(1, s.length - 1))

  function useQueryStates<T extends QueryState>(
    defaultState: T
  ): [T, (value: T) => void] {
    const [timer, setTimer] = useState(undefined)
    const [value, setValue] = useState(
      (): T =>
        fromPairs(
          map(([k, v]) => {
            const s = Array.isArray(v)
              ? queryParameterArray(k, Array.isArray(v) ? v : undefined)
              : queryParameter(
                  Array.isArray(v) ? `${k}[]` : k,
                  isNull(v)
                    ? v
                    : typeof v === 'string' || Array.isArray(v)
                    ? v
                    : v.toString()
                )
            return [
              k,
              typeof v === 'object'
                ? typeof s === 'object'
                  ? s
                  : v
                : typeof v === 'number' && typeof s === 'string'
                ? parseInt(s, 10)
                : typeof v === 'boolean' && typeof s === 'string'
                ? s === ''
                  ? v
                  : /false|no|0/.test(s)
                  ? false
                  : true
                : s,
            ]
          }, toPairs(defaultState))
        ) as T
    )

    useEffect(() => {
      clearTimeout(timer)
      setTimer(
        setTimeout(() => {
          const keysAtDefaultValues = filter(
            k =>
              isNull(value[k])
                ? value[k] === defaultState[k]
                : typeof value[k] === 'object'
                ? value[k].sort().join(',') === defaultState[k].sort().join(',')
                : value[k] === defaultState[k],
            Object.keys(value)
          )
          const placeholders = routePlaceholders(router.pathname)

          const mapArrayKeys = (value: T) =>
            fromPairs(
              toPairs(value).map(([k, v]) =>
                Array.isArray(defaultState[k]) ? [`${k}[]`, v] : [k, v]
              )
            )

          const newQuery = omit(
            concat(keysAtDefaultValues, placeholders),
            mapArrayKeys({
              ...query,
              ...value,
            })
          )

          router.push(
            {
              pathname: router.pathname,
              query: newQuery,
            },
            {
              pathname: router.asPath.replace(/\?.*/, ''),
              query: newQuery,
            },
            { shallow: true }
          )
        }, 800)
      )
    }, [value])

    return [
      value,
      (value: T) => {
        setValue(
          (fromPairs(
            map(([k, v]) => {
              return [k, typeof value[k] !== typeof v ? v : value[k]]
            }, toPairs(defaultState))
          ) as unknown) as T
        )
      },
    ]
  }

  const useQueryState = (
    key: string,
    defaultValue?: string,
    onChange?: (key: string, value: string) => void
  ): [string | undefined, (value: string) => void] => {
    const [timer, setTimer] = useState(undefined)
    const [value, setValue] = useState(() => queryParameter(key, defaultValue))
    useEffect(() => {
      clearTimeout(timer)
      setTimer(
        setTimeout(() => {
          const empty =
            value === defaultValue ||
            (typeof defaultValue === 'undefined' && value.length === 0)

          router.push(
            {
              pathname: router.pathname,
              query: omit(
                routePlaceholders(router.pathname),
                empty ? omit([key], query) : { ...query, [key]: value }
              ),
            },
            {
              pathname: router.asPath.replace(/\?.*/, ''),
              query: omit(
                routePlaceholders(router.pathname),
                empty ? omit([key], query) : { ...query, [key]: value }
              ),
            },
            { shallow: true }
          )
          if (onChange) {
            onChange(key, value)
          }
        }, 800)
      )
    }, [value])

    return [
      value,
      (value: string) => {
        setValue(value)
      },
    ]
  }

  const populatePath = (path: string, vars: { [k: string]: string }): string =>
    routePlaceholders(path).reduce(
      (url, placeholder) =>
        vars[placeholder]
          ? url.replace(`[${placeholder}]`, vars[placeholder])
          : url,
      path
    )

  return {
    query,
    queryParameter,
    queryParameterInteger,

    useQueryStates,
    useQueryState,

    useQueryNumber: (
      key: string,
      defaultValue?: number
    ): [number | undefined, (value?: number) => void] => {
      const [value, setValue] = useQueryState(
        key,
        defaultValue ? defaultValue.toString() : undefined
      )
      return [
        typeof value === 'undefined' ? undefined : parseInt(value, 10),
        (v?: number) =>
          setValue(
            typeof v === 'undefined' || v === defaultValue
              ? defaultValue
                ? defaultValue.toString()
                : undefined
              : v.toString()
          ),
      ]
    },

    populatePath,

    navigate: (to: string | { href: string; as: string }) =>
      typeof to === 'string' ? router.push(to) : router.push(to.href, to.as),

    up: () => router.push(router.asPath.replace(/\/[^/]*$/, '')),

    homePage: () => ({ href: mainSiteURL('/') }),

    mainSiteURL,
    apiURL,
    dashboardURL,

    getURLWithQuery: (query: { [k: string]: string }) =>
      url(
        router.pathname,
        router.pathname,
        toPairs(filter(Boolean, { ...router.query, ...query }))
      ).href,

    here: () => router.asPath,

    isCurrentPath: path => router.asPath === path,
    isCurrentPathPrefix: (path: string, indexOnly = false) =>
      router.asPath.substring(0, path.length) ===
        path.replace(/^https?:\/\/[^\/]+/, '') &&
      (!indexOnly || router.asPath.length <= path.length),
    isCurrentPathChild: (path: string) =>
      router.asPath.startsWith(path.replace(/^https?:\/\/[^\/]+/, '')),
    profileURL: user => ({
      href: mainSiteURL(`/members/${encodeURIComponent(objectID(user))}`),
    }),
    groupProfileURL: group => ({
      href: mainSiteURL(`/groups/${encodeURIComponent(objectID(group))}`),
    }),
    groupEditURL: group => ({
      href: mainSiteURL(`/groups/${encodeURIComponent(objectID(group))}/edit`),
    }),
    caseURL: entry => ({
      href: mainSiteURL(`/cases/${encodeURIComponent(objectID(entry))}`),
    }),
    casePDFURL: entry => ({
      href: mainSiteURL(`/cases/${encodeURIComponent(objectID(entry))}/pdf`),
    }),
    commentsDashboardURL: () => url('comments', 'comments', parameters([])),
    caseDashboardURL: () => url('cases', 'cases', parameters([])),

    groupDashboardURL: (group, section?: string) =>
      url(
        'groups/[id]' + (section ? `/${section}` : ''),
        'groups',
        parameters([
          ['id', objectID(group)],
          ['section', section],
        ])
      ),

    groupImportJobURL: (group, job) =>
      url(
        'groups/[id]/uploads/[job]',
        'groups',
        parameters([
          ['id', objectID(group)],
          ['section', 'uploads'],
          ['job', objectID(job)],
        ])
      ).as,

    groupExportURL: (group, exportName) => ({
      href: apiURL(
        `/admin/api/groups/${encodeURIComponent(objectID(group))}/${exportName}`
      ),
    }),

    sidebarResourceDashboardURL: () =>
      url('sidebar_resources', 'sidebar_resources', parameters([])),

    carouselDashboardURL: () => url('carousels', 'carousels', parameters([])),

    nativeAdsDashboardURL: () =>
      url('native_ads', 'native_ads', parameters([])),

    promoLinksAdsDashboardURL: () =>
      url('promo_links', 'promo_links', parameters([])),

    appInviteScreensDashboardURL: () =>
      url('app_invite_screens', 'app_invite_screens', parameters([])),

    institutionProfileURL: institution => ({
      href: mainSiteURL(
        `/institutions/${encodeURIComponent(objectID(institution))}`
      ),
    }),
    institutionDashboardURL: () =>
      url('institutions', 'institutions', parameters([])),

    unverifiedInstitutionDashboardURL: () =>
      url(
        'institutions/unverified_institutions',
        'unverified_institutions',
        parameters([])
      ),

    segmentDashboardURL: () => url('segments', 'segments', parameters([])),
    newsletterEditorURL: (path: string) =>
      `${siteConfig.urlScheme}://${siteConfig.newsletterEditorHostName}/admin/newsletter${path}`,
  }
}
