import getConfig from 'next/config'
import { toPairs } from 'ramda'

const siteConfig = getConfig().publicRuntimeConfig

type Endpoint = {
  scheme?: string
  hostName: string
  path: string
}

export const apiURL = (endpoint: string | Endpoint) =>
  `${
    typeof endpoint !== 'string' && endpoint.scheme
      ? endpoint.scheme
      : siteConfig.urlScheme
  }://${
    typeof endpoint === 'string'
      ? siteConfig.backendHostName
      : endpoint.hostName
  }` + (typeof endpoint === 'string' ? endpoint : endpoint.path)

const fetchOptions = {
  method: 'GET',
  mode: 'cors',
  cache: 'default',
  credentials: 'include',
  headers: new Headers({
    'Content-Type': 'application/json',
    'x-meds-svc': 'dash',
  }),
} as RequestInit

const standardResponseHandler = r => {
  if (r.ok) {
    return r.json()
  }
  return r.json().then(body => {
    const e: Error & { response?: { body: any } } = new Error(
      `Error ${r.status} ${r.statusText}`
    )
    e.response = { body }
    throw e
  })
}

export const fetchApi = {
  // GET
  get: (apiPath: string | Endpoint) =>
    fetch(apiURL(apiPath), fetchOptions).then(standardResponseHandler),
  // POST
  post: (
    apiPath: string | Endpoint,
    body,
    files = undefined,
    onProgress?: (e: any) => void
  ) => {
    const postData = new FormData()
    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-meds-svc': 'dash',
    })

    if (!!files) {
      // content-type header is set automatically on multipart/form-data
      headers.delete('Content-Type')
      toPairs(body).forEach(([name, value]) => {
        postData.set(name, value)
      })
      toPairs(files).forEach(([name, file]) => {
        postData.append(name, file)
      })
    }

    const request = new Request(apiURL(apiPath), {
      ...fetchOptions,
      method: 'POST',
      headers: headers,
      body: !!files ? postData : JSON.stringify(body),
    })

    // nosemgrep
    return fetch(request).then(standardResponseHandler)
  },
  // DELETE
  delete: (apiPath: string | Endpoint) =>
    fetch(apiURL(apiPath), { ...fetchOptions, method: 'DELETE' }).then(
      standardResponseHandler
    ),
  // PATCH
  patch: (
    apiPath: string | Endpoint,
    body,
    files = undefined,
    onProgress?: (e: any) => void
  ) => {
    const postData = new FormData()
    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-meds-svc': 'dash',
    })

    if (!!files) {
      // content-type header is set automatically on multipart/form-data
      headers.delete('Content-Type')
      toPairs(body).forEach(([name, value]) => {
        postData.set(name, value)
      })
      toPairs(files).forEach(([name, file]) => {
        postData.append(name, file)
      })
    }

    const request = new Request(apiURL(apiPath), {
      ...fetchOptions,
      method: 'PATCH',
      headers: headers,
      body: !!files ? postData : JSON.stringify(body),
    })

    // nosemgrep
    return fetch(request).then(standardResponseHandler)
  },
}

export const useAuthenticatedPostRequest = () => {
  return async (
    path: string | Endpoint,
    body = {},
    files = undefined,
    onProgress?: (e: any) => void
  ) =>
    await fetchApi
      .post(path, body, files, onProgress)
      .then(res => ({ body: res }))
}

export const useAuthenticatedPatchRequest = () => {
  return async (
    path: string | Endpoint,
    body = {},
    files = undefined,
    onProgress?: (e: any) => void
  ) =>
    await fetchApi
      .patch(path, body, files, onProgress)
      .then(res => ({ body: res }))
}

export const useAuthenticatedDeleteRequest = () => {
  return async (path: string | Endpoint) =>
    await fetchApi.delete(path).then(res => ({
      body: res,
    }))
}
