import { useEffect, useState } from 'react'
import {
  defaultTo,
  filter,
  fromPairs,
  map,
  omit,
  path,
  pick,
  pipe,
  prop,
  toPairs,
  uniq,
  values,
} from 'ramda'
import { schema } from 'normalizr'
import { useDispatch, useSelector } from 'react-redux'

import {
  useResourceUpdater,
  list,
  mutateMany,
  dispatchResponseNotification,
  entity,
  useResourceReloader,
  useDedupedAPI,
} from './api'
import { useAuthenticatedPostRequest } from './fetchApi'

import {
  BasicMember,
  BasicUser,
  BasicUserEntry,
  MembersList,
  ResponseEntity,
  ResultsList,
  RuleMap,
} from './types'
import { LogType } from './log'

export const BasicUserSchema = new schema.Entity('basic_user')
export const UserSchema = new schema.Entity('user')

export const BasicUserEntrySchema = new schema.Entity(
  'user_entry',
  {
    target: BasicUserSchema,
  },
  { idAttribute: path(['target', 'id']) }
)

export const UserResponse = new schema.Entity(
  'user_response',
  {
    response: UserSchema,
  },
  { idAttribute: '__url__' }
)

const UsersResponse = new schema.Entity(
  'users_response',
  {
    users: [BasicUserSchema],
  },
  { idAttribute: '__url__' }
)

export const getAutoConnectionID = x => prop('id', x)

const AutoConnectionSchema = new schema.Entity(
  'auto_connection',
  {},
  {
    idAttribute: getAutoConnectionID,
  }
)

const AutoConnectionsResponse = new schema.Entity(
  'auto_connections_response',
  {
    results: [AutoConnectionSchema],
    users: new schema.Values(BasicUserSchema),
    groups: new schema.Values(new schema.Entity('group')),
  },
  { idAttribute: '__url__' }
)

export const useMember = (id: string | BasicUser): BasicUser | undefined => {
  const member = useSelector((state): BasicUser | undefined =>
    typeof id === 'string'
      ? path(['entities', BasicUserSchema.key, id], state)
      : id
  )
  const reloadResource = useResourceReloader()

  useEffect(() => {
    if (typeof id === 'string' && !member) {
      reloadResource(
        `/api/members/list?user_ids[]=${id}`,
        UsersResponse,
        `/api/members/list?user_ids[]=${id}`
      )
    }
  }, [id])

  return typeof id === 'string' ? member : id
}

export const useFullMember = (id: string) =>
  useDedupedAPI<ResponseEntity<BasicMember>>(
    `/admin/api/users/${encodeURIComponent(id)}`
  )

export const useMembersMap = (ids: string[]): { [id: string]: BasicUser } => {
  const userList = useMembers(ids)
  return fromPairs(userList.map(x => [x.id, x]))
}

export const useMembers = (ids: string[]): Array<BasicUser> => {
  ids = uniq(ids)
  const members = useSelector(
    pipe(
      path(['entities', BasicUserSchema.key]),
      defaultTo({ users: [] }),
      pick(ids),
      values
    )
  )
  const qstring = 'ids[]=' + ids.join('&ids[]=')
  const reloadResource = useResourceReloader()

  useEffect(() => {
    if (members.length !== ids.length) {
      reloadResource(
        `/admin/api/users/list?${qstring}`,
        UsersResponse,
        `/admin/api/users/list?${qstring}`
      )
    }
  }, [qstring])
  return members
}

export const useMembersList = (ids: string[]) => {
  const qstring = 'ids[]=' + ids.join('&ids[]=')
  const apiPath = `/admin/api/users/list?${qstring}`

  const { data, error } = useDedupedAPI<MembersList<BasicMember>>(apiPath)
  return [list(data), !data]
}

export const useMemberSearch = (
  page,
  term = ''
): [ResultsList<BasicUserEntry> | undefined, boolean] => {
  const apiPath = `/api/search?type[]=user&page=${page}&q=${encodeURIComponent(
    term
  )}`
  const { data, error } = useDedupedAPI(apiPath)
  return [list(data), !!data]
}

export const useAutoConnections = () => {
  const dispatch = useDispatch()
  const updateResource = useResourceUpdater()
  const id = `/admin/api/auto-connections`
  const apiPath = `/admin/api/auto-connections`
  const { data: response, refresh } = useDedupedAPI(apiPath)
  const save = async data => {
    try {
      await updateResource(
        apiPath,
        AutoConnectionsResponse,
        data,
        undefined,
        id
      )
      refresh()
    } catch (e) {
      dispatchResponseNotification(dispatch, e.response)
    }
  }
  return [list(response), save]
}

export const useAllMembers = (
  query: { [k: string]: string | number } = {},
  search: { [k: string]: any } = {}
): [MembersList<BasicMember> | undefined, boolean] => {
  const queryString = map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    filter(([k, v]) => v !== undefined, toPairs(query))
  ).join('&')
  const searchString = JSON.stringify(search)

  const apiPath = `/admin/api/users?${queryString}&q=${searchString}`

  const { data, error } = useDedupedAPI<ResultsList<BasicMember>>(apiPath)
  return [list(data), !data]
}

export const useAllRequestedNameChanges = (
  query: { [k: string]: string | number } = {},
  search: { [k: string]: any } = {}
): [MembersList<BasicMember> | undefined, boolean] => {
  const queryString = map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    filter(([k, v]) => v !== undefined, toPairs(query))
  ).join('&')
  const searchString = JSON.stringify(search)

  const apiPath = `/admin/api/users/name_change_requests?${queryString}&q=${searchString}`

  const { data, error } = useDedupedAPI<ResultsList<BasicMember>>(apiPath)
  return [list(data), !data]
}

export const useMemberStatsEvents = (type: string) => {
  const apiPath = `/api/user-log/events/` + type

  const { data } = useDedupedAPI<ResultsList<LogType>>(apiPath)
  return [list(data), !data]
}

export const useMemberLogs = (id: string, query: { [k: string]: any } = {}) => {
  const queryString = map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    filter(([k, v]) => v !== undefined, toPairs(query))
  ).join('&')

  const apiPath = `/admin/api/users/${encodeURIComponent(
    id
  )}/logs?${queryString}`

  const { data, error } = useDedupedAPI<ResultsList<LogType>>(apiPath)
  return [list(data), !data]
}

export const useMemberStats = (
  id: string,
  query: { [k: string]: any } = {}
) => {
  const queryString = map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    filter(([k, v]) => v !== undefined, toPairs(query))
  ).join('&')

  const apiPath = `/api/user-log/${encodeURIComponent(id)}?${queryString}`

  const { data, error } = useDedupedAPI<ResultsList<LogType>>(apiPath)
  return [list(data), !data]
}

export const useMembersSearchRules = () => {
  const { data, error } = useDedupedAPI<RuleMap>(
    '/admin/api/users/search_rules'
  )
  return [list(data), !data]
}

export const useMemberAdminRules = () => {
  const { data, error } = useDedupedAPI<RuleMap>(`/admin/api/users/rules`)
  return [list(data), !data]
}

export const useMembersRules = id => {
  const { data, error } = useDedupedAPI<RuleMap>(`/admin/api/users/${id}/rules`)
  return [list(data), !data]
}

export const useMemberNotificationPreferencesRules = () => {
  const { data, error } = useDedupedAPI<RuleMap>(
    '/admin/api/users/notification_preferences_rules'
  )
  return [list(data), !data]
}

export const useMemberGroupInstitutionMembership = (
  id,
  type = 'groups',
  query: { [k: string]: any } = {}
) => {
  const queryString = map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    filter(([k, v]) => v !== undefined, toPairs(query))
  ).join('&')
  const { data, error } = useDedupedAPI<RuleMap>(
    `/admin/api/users/${id}/${type}?${queryString}`
  )
  return [list(data), !data]
}

export const useMemberSystemLevelAccess = id => {
  const { data, error } = useDedupedAPI<RuleMap>(
    `/admin/api/users/${id}/system_level_access`
  )
  return [list(data), !data]
}

export const useMemberNotificationPreferences = id => {
  const { data, error } = useDedupedAPI<RuleMap>(
    `/admin/api/users/${id}/notification_preferences`
  )
  return [list(data), !data]
}

export const membersReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        data: action.payload,
      }
    default:
      throw new Error()
  }
}

export const useJobTitleSearch = term => {
  const apiPath = `/api/job-titles/search-for-profile?term=${term}`

  const { data, error } = useDedupedAPI<ResultsList<LogType>>(apiPath)
  return [list(data), !data]
}

export const useCreatableTypes = () => {
  return useDedupedAPI<{
    types: { id: string; label: string; require: string[] }[]
  }>(`/admin/api/users/create-types`)
}

export const useMemberEditor = (member?: BasicMember) => {
  const dispatch = useDispatch()
  const [form, setForm] = useState(member ?? ({} as BasicMember))
  const post = useAuthenticatedPostRequest()
  const updateResource = useResourceUpdater()
  const apiPath = '/admin/api/users' + (form.id ? '/' + form.id : '')

  return {
    data() {
      return form
    },

    async commonAction(action): Promise<boolean> {
      try {
        const res = await updateResource(
          apiPath + '/' + action,
          UserResponse,
          {}
        )
        mutateMany(
          key => key.startsWith('/admin/api/users'),
          data => undefined
        )

        dispatchResponseNotification(dispatch, res, {
          text: 'Operation completed successfully.',
          color: 'green',
        })
        return true
      } catch (e) {
        dispatchResponseNotification(dispatch, e.response)
        return false
      }
    },

    async updateNotificationPreferences(preferences): Promise<any> {
      try {
        const res = await updateResource(
          apiPath + '/notification_preferences',
          UserResponse,
          preferences
        )
        mutateMany(
          key =>
            key.startsWith(
              `/admin/api/users/${member.id}/notification_preferences`
            ),
          data => undefined
        )
        dispatchResponseNotification(dispatch, {
          body: { message: 'Notification Preferences changed successfully.' },
        })
        return res
      } catch (e) {
        return false
      }
    },

    async addLogEntry(log): Promise<any> {
      try {
        const res = await updateResource(apiPath + '/log', UserResponse, log)
        mutateMany(
          key => key.startsWith(`/admin/api/users/${member.id}/logs`),
          data => undefined
        )
        dispatchResponseNotification(dispatch, {
          body: { message: 'Log entry added successfully.' },
        })
        return res
      } catch (e) {
        return false
      }
    },

    async create(fields): Promise<any> {
      return post('/admin/api/users', fields).then(res => {
        if (res.body.errors) {
          throw new Error(res.body.errors.join('\n'))
        }
        if (res.body.error) {
          throw new Error(res.body.error)
        }
        return res.body
      })
    },

    async upload(id, fields, files): Promise<boolean> {
      try {
        const path = `/admin/api/users/${id}`
        const job = entity(
          await updateResource(
            path,
            UserResponse,
            {
              user: JSON.stringify(omit(['log'], fields)),
              log: JSON.stringify(pick(['log'], fields)['log']),
            },
            files
          )
        )
        return job
      } catch (e) {
        dispatchResponseNotification(dispatch, e.response)
        return false
      }
    },
  }
}
