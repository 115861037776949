import { path } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Message } from 'semantic-ui-react'

class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  static getDerivedStateFromError(error) {
    const message =
      path(['response', 'body', 'error'], error) ||
      path(['message'], error) ||
      'Sorry, an error occurred'
    return { error, message }
  }

  componentDidCatch(error, info) {
    console.log('AN ERROR')
    console.log(error, info)
  }

  handleRetry() {
    this.props.dispatch(this.state.error.retry)
    this.setState({ error: false })
  }

  render() {
    if (this.state.error) {
      return (
        <Message
          negative
          content={
            <RetryButton
              message={this.state.message}
              onRetry={this.state.error.retry ? () => this.handleRetry() : null}
            />
          }
        />
      )
    }
    return this.props.children
  }
}

export const ErrorBoundary = connect(null, dispatch => ({
  dispatch,
}))(ErrorBoundaryComponent)

const RetryButton = ({ message, onRetry }) => (
  <div>
    {onRetry && (
      <Button.Group size="mini" style={{ float: 'right', marginTop: '-5px' }}>
        <Button positive content="Retry" size="mini" onClick={onRetry} />
      </Button.Group>
    )}
    <div>{message}</div>
  </div>
)
