import { useEffect, useCallback, useState } from 'react'

type Size = {
  width: number
  height: number
}

const getWindowInnerSize = () =>
  typeof window === 'undefined'
    ? {
        width: 0,
        height: 0,
      }
    : {
        width: window.innerWidth,
        height: window.innerHeight,
      }

export const useWindowSize = (
  getSize: () => Size = getWindowInnerSize
): Size => {
  const [size, setSize] = useState(getSize)
  const ssr = typeof window === 'undefined'
  const onResize = useCallback(() => setSize(getSize()), [
    ssr ? 0 : window.innerWidth,
  ])
  useEffect(() => {
    if (ssr) {
      return
    }
    window.addEventListener('resize', onResize)
    window.addEventListener('orientationchange', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('orientationchange', onResize)
    }
  }, [ssr ? 0 : window.innerWidth])

  return size
}
