import React from 'react'
import Link from 'next/link'

const SiteLink = ({
  children,
  prefetch,
  ...props
}: { children: React.ReactNode; prefetch?: boolean } & any) => (
  // nextjs 9 defaults to prefetch=true, we change that here
  <Link prefetch={!!prefetch} {...props} legacyBehavior>
    {children}
  </Link>
)

export default SiteLink
