import {
  memoizeWith,
  last,
  reduce,
  pipe,
  map,
  fromPairs,
  prop,
  path,
  chain,
  identity,
  reverse,
  sortBy,
  uniqBy,
} from 'ramda'
import * as R from 'ramda'

import { Category, FieldType, SpecialtyType, SubspecialtyType } from './types'

type AnyCategory = FieldType | SpecialtyType | SubspecialtyType

type CategoryWithParent = AnyCategory & { parent?: Category }

const isParentCategory = (x: AnyCategory): x is FieldType | SpecialtyType => {
  return typeof x['children'] !== 'undefined'
}

const categoryListToObject = memoizeWith(JSON.stringify, (xs: AnyCategory[]) =>
  fromPairs(xs.map((x: Category) => [x.id, x]))
)

const flattenCategoryList = (xs: AnyCategory[], parent?: CategoryWithParent) =>
  chain((x: AnyCategory) =>
    [{ ...x, parent }].concat(
      flattenCategoryList(isParentCategory(x) ? x.children : [], {
        ...x,
        parent,
      })
    )
  )(xs)

const nestedCategoryListToObject = pipe(
  flattenCategoryList,
  categoryListToObject
)

const specialtyObject = (categories, id) =>
  prop(id)(nestedCategoryListToObject(categories.specialties))

const selfAndAncestry = x =>
  chain(identity, [x].concat(x.parent ? selfAndAncestry(x.parent) : []))

const specialtyObjectPath = (categories, id) =>
  reverse(selfAndAncestry(specialtyObject(categories, id)))

const d = v => {
  console.log(v)
  return v
}

const CategoryRenderer = categories => ({
  role: id => path([id, 'label'])(categoryListToObject(categories.roles)),
  roleOptions: () => categories.roles,
  seniorityOptions: (role_id?: string) => {
    return role_id
      ? categories.seniorities[role_id]
      : sortBy(
          prop('label'),
          uniqBy(
            prop('id'),
            Object.keys(categories.seniorities).reduce(
              (all, role_id) => [...all, ...categories.seniorities[role_id]],
              []
            )
          )
        )
  },
  seniority: (role_id, id) =>
    role_id === 'other' && id === 'advisory_board_mod'
      ? 'Non-clinical member'
      : path([id, 'label'])(
          categoryListToObject(prop(role_id)(categories.seniorities) || [])
        ),
  specialty: id =>
    path([id, 'label'], nestedCategoryListToObject(categories.specialties)),
  specialtyPath: id => map(prop('label'), specialtyObjectPath(categories, id)),
  minimalSpecialtyPath: id =>
    reduce(
      (a, v) => (last(a) === v ? a : a.concat(v)),
      [],
      map(prop('label'), specialtyObjectPath(categories, id))
    ),
})

export default CategoryRenderer
